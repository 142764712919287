import { useMutation, useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../lib/apiClient";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useState } from "react";

import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import UpdatePartnerHook from "../components/forms/PartnerHookUpdate";
import SelectFormField from "../components/inputs/select-field/select-form-field";
import { useForm } from "react-hook-form";

const PartnerHook = () => {
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [state, setState] = useState({
    drawerOpen: false,
    isEdit: false,
  });

  const params = useParams();

  const { control, watch, getValues } = useForm({ mode: "all" });
  watch();

  const [updatePartnerHook] = useMutation<ApiClient.GetPartnerHook>(
    apiClient.mutations.UPDATE_PARTNER_HOOK
  );

  const { data, loading, error, refetch } = useQuery<ApiClient.GetPartnerHook>(
    apiClient.queries.GET_PARTNER_HOOK,
    {
      variables: {
        id: params.id,
      },
    }
  );

  const { data: tablesOptions } = useQuery<ApiClient.GetEntities>(
    apiClient.queries.GET_ENTITIES
  );

  const optionsToRefresh =
    tablesOptions?.getEntities?.map((obj) => ({
      label: obj?.name,
      value: obj?.key,
    })) || [];

  const handleActivateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (data?.partnerHook.id) {
      updatePartnerHook({
        variables: {
          partnerHook: {
            id: data.partnerHook.id,
            isActive: e.target.checked,
          },
        },
      }).then(() => {
        refetch();
      });
    }
  };

  const handleEditOn = () => {
    setState({
      ...state,
      isEdit: true,
    });
  };

  const handleEditOff = () => {
    setState({
      ...state,
      isEdit: false,
    });
  };

  const [markEntityAsFetched] = useMutation(
    apiClient.mutations.MARK_ENTITY_AS_FETCHED
  );

  const handleRefresh = () => {
    const values = getValues();
    setIsRefreshLoading(true);

    markEntityAsFetched({
      variables: {
        partnerHookId: data?.partnerHook?.id,
        markEntitiesAsFetched: { entityType: values?.refreshTable },
      },
    })
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        setIsRefreshLoading(false);
      });
  };

  return (
    <Layout page="PartnerHooks">
      {loading ? (
        <>LOADING...</>
      ) : error ? (
        <>{error.message}</>
      ) : (
        <>
          {data?.partnerHook?.id && (
            <div style={{ textAlign: "left" }}>
              <Button
                onClick={handleEditOn}
                style={{ float: "right" }}
                variant="contained"
              >
                Edit
              </Button>
              {state.isEdit && data.partnerHook.id ? (
                <UpdatePartnerHook
                  partnerHookId={data.partnerHook.id}
                  onComplete={handleEditOff}
                />
              ) : (
                <>
                  <div
                    style={{
                      width: "350px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <SelectFormField
                      label="Select table to refresh"
                      name="refreshTable"
                      control={control}
                      options={optionsToRefresh}
                    />
                    <div style={{ marginTop: "31px" }}>
                      <Button
                        onClick={handleRefresh}
                        style={{ float: "right" }}
                        variant="contained"
                        disabled={
                          isRefreshLoading || !getValues()?.refreshTable
                        }
                        startIcon={
                          isRefreshLoading && (
                            <CircularProgress size={20} color="secondary" />
                          )
                        }
                      >
                        Refresh
                      </Button>
                    </div>
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={data.partnerHook.isActive}
                        onChange={handleActivateChange}
                      />
                    }
                    label="Is Active"
                  />
                  <br />
                  Id: {data.partnerHook.id}
                  <br />
                  Name: {data.partnerHook.name}
                  <br />
                  Headers:{" "}
                  {data.partnerHook.headers.map((header, i) => (
                    <div key={i}>
                      {header.name}: {header.value}
                    </div>
                  ))}
                  <br />
                  Partner Id: {data.partnerHook.partnerId}
                  <br />
                  Practice Id: {data.partnerHook.practiceId}
                  <br />
                  Url: {data.partnerHook.url}
                  <br />
                  Is Active: {JSON.stringify(data.partnerHook.isActive)}
                  <br />
                  Created At: {data.partnerHook.createdAt}
                  <br />
                  Updated At: {data.partnerHook.updatedAt}
                  <br />
                </>
              )}
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default PartnerHook;
