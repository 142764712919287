import { useMutation, useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../lib/apiClient";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";

import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import UpdatePartner from "../components/forms/PartnerUpdate";

const Partner = ()=>{
    const [state, setState] = useState({
        drawerOpen: false,
        isEdit: false
    })

    const params = useParams()
    
    const [updatePartner] = useMutation<ApiClient.GetPartner>(apiClient.mutations.UPDATE_PARTNER);

    const { data, loading, error, refetch } = useQuery<ApiClient.GetPartner>(apiClient.queries.GET_PARTNER, {
        variables: {
            id: params.id
        }
    });
    
    const handleActivateChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
        if(data?.partner.id ){
            updatePartner({
                variables: {
                    partner: {
                        id: data.partner.id,
                        isActive: e.target.checked
                    }
                }
            }).then(()=>{
                refetch()
            })
        }
    }

    const handleEditOn = ()=>{
        setState({
            ...state,
            isEdit: true
        })
    }

    const handleEditOff = ()=>{
        setState({
            ...state,
            isEdit: false
        })
    }

    console.log(data?.partner)
    return <Layout page="Partners">
        {loading ? (
            <>
                LOADING...
            </>
        ):error?(
            <>{error.message}</>
        ):(
            <>  
                {data?.partner?.id && (
                    <div style={{textAlign: 'left'}}>
                        <Button onClick={handleEditOn} style={{float: 'right'}} variant="contained">Edit</Button>
                        {state.isEdit && data.partner.id?(
                            <UpdatePartner partnerId={data.partner.id} onComplete={handleEditOff}/>
                        ):(
                            <>
                                <br />
                                Id: {data.partner.id}<br />
                                Name: {data.partner.name}<br />

                                
                                Is Single Client Ownership: {JSON.stringify(data.partner.singleClientOwnership)}<br />
                                
                                Created At: {data.partner.createdAt}<br />
                                Updated At: {data.partner.updatedAt}<br />
                            </>
                        )}
                    </div>
                )}
            </>
        )}
    </Layout>
}

export default Partner