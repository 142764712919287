//lib
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import { FieldError } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import { SelectFieldStyled } from "./select-field.style";

interface SelectFieldProps {
  name: string;
  label?: string;
  error?: FieldError;
  isMultiple: boolean;
  placeholder: string;
  defaultValue?: string | string[];
  onChange?: (value: unknown) => void;
  options: { label: string; value: string }[];
  value: string | number | boolean | string[] | number[] | boolean[];
}

const SelectField = (props: SelectFieldProps) => {
  const {
    name,
    options,
    value,
    error,
    onChange,
    placeholder,
    isMultiple,
    defaultValue,
    label,
  } = props;

  return (
    <SelectFieldStyled>
      {label ? <div className="label">{label}</div> : <></>}
      <Select
        multiple={isMultiple}
        id={name}
        value={value}
        onChange={onChange}
        sx={{
          width: "100%",
          padding: "5px 0px",
          height: isMultiple ? "auto" : "40px",
        }}
        defaultValue={defaultValue}
        placeholder={placeholder}
        renderValue={(selected) => (
          <Box className="chips-container">
            {typeof selected === "object" &&
              selected.map((select) => {
                const label = options?.find(
                  (option) => option?.value === select
                )?.label;

                return <Chip key={`${label}-1`} label={label} />;
              })}
            {typeof selected === "string" &&
              options?.find((option) => option?.value === selected)?.label}
          </Box>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option?.value} value={option?.value}>
            {option?.label}
          </MenuItem>
        ))}
      </Select>
      <p className="error">{error?.message}</p>
    </SelectFieldStyled>
  );
};

export default SelectField;
