//lib
import SelectField from "./select-field";
import { Controller } from "react-hook-form";

interface SelectFormFieldType {
  name: string;
  control: any;
  options: { label: string; value: string }[];
  placeholder?: string;
  label?: string;
  isMultiple?: boolean;
  defaultValue?: string | string[];
}

const SelectFormField = (props: SelectFormFieldType) => {
  const {
    name,
    control,
    options,
    placeholder = "",
    label = "",
    isMultiple = false,
    defaultValue,
  } = props;

  const defaultValueForward = defaultValue
    ? defaultValue
    : isMultiple
    ? []
    : "";
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValueForward}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <SelectField
          name={name}
          options={options}
          error={error}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          label={label}
          isMultiple={isMultiple}
          defaultValue={defaultValueForward}
        />
      )}
    />
  );
};

export default SelectFormField;
