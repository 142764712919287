import Layout from "../../components/Layout";
import { Link } from "react-router-dom";

const Settings = ()=>{
    
    return <Layout page="Settings">
       {/* <Link to="/settings/species">Species (Sexes and Breeds)</Link> */}
    </Layout>
}

export default Settings