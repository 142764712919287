import { useNavigate } from "react-router-dom"
import useSession from "../../helpers/useSessionState"
import { Divider, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import DashboardIcon from '@mui/icons-material/Dashboard';

import GroupsIcon from '@mui/icons-material/Groups';
import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LayersIcon from '@mui/icons-material/Layers';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import SettingsIcon from '@mui/icons-material/Settings';

const MenuBar = ()=>{
    const navigate = useNavigate()
    
    const [sessionState] = useSession()
    // <Link to='/practices'>Practices</Link>
    // <Link to='/sites'>Sites</Link>
    // <Link to='/calendar'>Calendar</Link>
    const navigateTo = (target: string)=>(
        navigate(target)
    )
    return (
        <>
            {/* <ListItemButton onClick={navigateTo.bind(null, '/')}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton onClick={navigateTo.bind(null, '/today')}>
                <ListItemIcon>
                    <CalendarViewWeekIcon />
                </ListItemIcon>
                <ListItemText primary="Today" />
            </ListItemButton>

            <Divider sx={{ my: 1 }} /> */}
{/* 
            <ListItemButton onClick={navigateTo.bind(null, '/schedule')}>
                <ListItemIcon>
                    <CalendarMonthOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Schedule" />
            </ListItemButton> */}
{/* 
            <ListItemButton onClick={navigateTo.bind(null, '/clients')}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Clients / Patients" />
            </ListItemButton>


            <Divider /> */}

            <ListItemButton>
                <ListItemIcon>
                    <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Integrations" />
            </ListItemButton>
{/* 
            <ListItemButton onClick={navigateTo.bind(null, '/settings')}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
            </ListItemButton> */}

            <ListItemButton onClick={navigateTo.bind(null, '/web-hooks')}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Web Hooks" />
            </ListItemButton>

            <Divider />

            {sessionState?.isSuper && (
                <ListItemButton onClick={navigateTo.bind(null, '/partners')}>
                    <ListItemIcon>
                        <BusinessOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Partners" />
                </ListItemButton>
            )}

            <ListItemButton onClick={navigateTo.bind(null, '/practices')}>
                <ListItemIcon>
                    <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary="Practices" />
            </ListItemButton>
{/* 
            <ListItemButton onClick={navigateTo.bind(null, '/sites')}>
                <ListItemIcon>
                    <StoreOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Sites" />
            </ListItemButton> */}
            
        </>
    )
}

export default MenuBar