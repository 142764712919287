import {
    createBrowserRouter,
} from "react-router-dom";
import Partners from "./pages/Partners";
import Partner from "./pages/Partner";
import Practices from "./pages/Practices";
import Practice from "./pages/Practice";
import Sites from "./pages/Sites";
import Clients from "./pages/Clients";
import Home from "./pages/Home";
import Schedule from "./pages/Schedule";
import Patients from "./pages/Patients";
import Settings from "./pages/Settings";
import Species from "./pages/Settings/Species";
import Breeds from "./pages/Settings/Breeds";
import Sexes from "./pages/Settings/Sexes";
import PartnerHooks from "./pages/PartnerHooks";
import PartnerHook from "./pages/PartnerHook";

const router = createBrowserRouter([
    {
      path: "/partners",
      Component: Partners,
    },
    {
      path: "/partners/:id",
      Component: Partner,
    },
    {
      path: "/practices",
      Component: Practices,
    },
    {
      path: "/practices/:id",
      Component: Practice,
    },
    {
      path: "/sites",
      Component: Sites,
    },
    // {
    //   path: "/clients",
    //   Component: Clients,
    // },
    // {
    //   path: "/clients/:clientId/patients",
    //   Component: Patients,
    // },
    {
      path: "/settings",
      Component: Settings,
    },
    // {
    //   path: "/settings/species",
    //   Component: Species,
    // },
    // {
    //   path: "/settings/species/:speciesId/breeds",
    //   Component: Breeds,
    // },
    // {
    //   path: "/settings/species/:speciesId/sexes",
    //   Component: Sexes,
    // },
    // {
    //   path: "/schedule",
    //   Component: Schedule,
    // },
    {
      path: "/web-hooks",
      Component: PartnerHooks,
    },
    {
      path: "/web-hooks/:id",
      Component: PartnerHook,
    },
    {
      path: "/about",
      element: <div>About</div>,
    },
    {
        path: "*",
        Component: Home
    }
]);


export default router