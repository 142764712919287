import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../lib/apiClient";
import useSession from "../helpers/useSessionState";
import { Button, Drawer, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import CreatePractice from "../components/forms/PracticeCreate";

const Practices = ()=>{
    const [state, setState] = useState({
        drawerOpen: false
    })

    const navigate = useNavigate()
    const { loading, error, data, refetch } = useQuery<ApiClient.GetPractices>(apiClient.queries.GET_PRACTICES);

    const [sessionState, setSession] = useSession()
    const setDrawer = (value: boolean)=>{
        refetch()
        setState({
            ...state,
            drawerOpen: value
        })
    }
    
    const handleSelectPractice = (practice: ApiClient.GetPractices["practices"][0])=>{
        setSession({
            ...Object(sessionState),
            currentPracticeId: practice.id
        })
        navigate("/")
    }

    return <Layout page="Practices">
        {loading ? (
            <>
                LOADING...
            </>
        ):error?(
            <>{error.message}</>
        ):(
            <>
                {sessionState?.isSuper && sessionState?.currentPartnerId != null && (
                    <div>
                        <Button variant="contained" onClick={setDrawer.bind(null, true)} style={{float: 'right'}}>Create Practice</Button>
                        <Drawer
                            anchor='right'
                            open={state.drawerOpen}
                            onClose={setDrawer.bind(null, false)}
                        >
                            <CreatePractice onComplete={setDrawer.bind(null, false)}/>
                        </Drawer>
                    </div>
                )}
                
                {/* {data?.practices?.map((practice, i: Number)=>(
                    <div key={i.toString()}>
                        <Link to={`/practice/${practice.id}`}>
                            {practice.name}
                        </Link>
                    </div>
                ))} */}

                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Integration</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.practices?.map((practice, i: Number) => (
                            <TableRow
                                key={practice.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Link to={`/practices/${practice.id}`}>{practice.name}</Link>
                                </TableCell>
                                <TableCell align="right">{practice.integration}</TableCell>
                                <TableCell align="right">{practice.isActive}</TableCell>
                                <TableCell align="right">{practice.createdAt}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>
        )}
    </Layout>
}

export default Practices