import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import config from "../config";

loadDevMessages();
loadErrorMessages();

let params = new URLSearchParams(document.location.search);

const sessionString = localStorage.getItem("session");
let state = params.get("state");
if (sessionString) {
  const session = JSON.parse(sessionString);
  if (session?.currentLocationId) state = session.currentLocationId;
}

const uri = config.env.isDev
  ? "http://localhost:8080/graphql"
  : `https://${state}.vetsuite.io/graphql`;

const httpLink = createHttpLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const sessionString = localStorage.getItem("session");
  let session: { token?: string; currentPartnerId?: string } = {};
  try {
    if (sessionString) {
      session = JSON.parse(sessionString);
    }
  } catch (error) {
    console.log(error);
  }

  const token = session.token;
  let optional = {};
  if (token) {
    optional = {
      authorization: token ? `Bearer ${token}` : "",
      "X-Partner-Id": session.currentPartnerId,
    };
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...optional,
    },
  };
});
export namespace ApiClient {
  export type GetCalendar = GetAppointments & GetBlockoffs;

  export interface GetAppointments {
    appointments: {
      id: string;
      startsAt: string;
      endsAt: string;
      reason: string;
      client?: {
        id: string;
        firstName: string;
        lastName: string;
      };
      patient?: {
        id: string;
        name: string;
        breed?: {
          id: string;
          name: string;
          species?: {
            id: string;
            name: string;
          };
        };
        sex?: {
          id: string;
          name: string;
        };
      };
      resourceId: string;
      resource?: {
        id: string;
        firstName: string;
        lastName: string;
      };
      appointmentType?: {
        id: string;
        name: string;
      };
      appointmentStatus?: {
        id: string;
        name: string;
      };
    }[];
  }
  export interface GetResources {
    resources: {
      id: string;
      fullName: string;
    }[];
  }
  export interface GetBlockoffs {
    blockoffs: {
      id: string;
      startsAt: string;
      endsAt: string;
      reason: string;
      resourceId: string;
      resource?: {
        id: string;
        fullName: string;
      };
    }[];
  }
  export interface GetPartners {
    partners: {
      id: string;
      name: string;
      singleClientOwnership: boolean;
      createdAt: string;
      updatedAt: string;
    }[];
  }
  export interface GetPartner {
    partner: {
      id: string;
      name: string;
      singleClientOwnership: boolean;
      createdAt: string;
      updatedAt: string;
    };
  }

  export interface GetEntities {
    getEntities: {
      name: string;
      key: string;
    }[];
  }
  export interface GetPartnerHooks {
    partnerHooks: {
      id: string,
      name: string,
      partnerId: string,
      practiceId: string,

      sendAppointment: boolean,
      sendAppointmentStatus: boolean,
      sendAppointmentType: boolean,
      sendBlockoff: boolean,
      sendBreed: boolean,
      sendClient: boolean,
      sendClientPhoneNumber: boolean,
      sendCurrency: boolean,
      sendEmployee: boolean,
      sendInvoice: boolean,
      sendLanguage: boolean,
      sendLocale: boolean,
      sendPatientReminder: boolean,
      sendPatient: boolean
      sendOwnership: boolean,
      sendPractice: boolean,
      sendResource: boolean,
      sendSex: boolean,
      sendSpecies: boolean,
      sendTimezone: boolean,
      
      parser: string;

      headers: any;

      isActive: boolean;
      url: string;

      createdAt: string;
      updatedAt: string;
    }[];
  }
  export interface GetPartnerHook {
    partnerHook: {
      id: string
      name: string
      partnerId: string
      practiceId: string
      
      sendAppointment: boolean
      sendAppointmentStatus: boolean
      sendAppointmentType: boolean
      sendBlockoff: boolean
      sendBreed: boolean
      sendClient: boolean
      sendClientPhoneNumber: boolean
      sendCurrency: boolean
      sendEmployee: boolean
      sendInvoice: boolean
      sendLanguage: boolean
      sendLocale: boolean
      sendPatientReminder: boolean
      sendPatient: boolean
      sendOwnership: boolean
      sendPractice: boolean
      sendResource: boolean
      sendSex: boolean
      sendSpecies: boolean
      sendTimezone: boolean
      
      parser: string;

      headers: {
        name: string;
        value: string;
      }[];

      isActive: boolean;
      url: string;

      createdAt: string;
      updatedAt: string;
    };
  }
  export interface GetPractices {
    practices: {
      id: string;
      name: string;
      integration: string;
      isActive: boolean;
      createdAt: string;
    }[];
  }
  export interface GetPractice {
    practice: {
      id: string;
      name: string;
      colorCode: string;
      contact: string;
      currencyId: string;
      email: string;
      facebookUrl: string;
      goLiveDate: string;
      installation: string;
      siteId: number;
      integration: string;
      integrationId: string;
      isActive: boolean;
      isDeleted: string;
      label: string;
      languageId: string;
      localeId: string;
      migratedFrom: string;
      serverId: string;
      websiteUrl: string;
      timezoneId: string;
      privateKey: string;
      createdAt: string;
      updatedAt: string;
    };
  }
  export interface GetSites {
    sites: {
      id: string;
      name: string;
    }[];
  }
  export interface GetClients {
    clients: {
      id: string;
      firstName: string;
      lastName: string;
      integrationId: string;
      emails?: {
        id: string;
        isPrimary: boolean;
        type: string;
        value: string;
      }[];
      phoneNumbers?: {
        id: string;
        isPrimary: boolean;
        type: string;
        value: string;
      }[];
      createdAt: string;
      updatedAt: string;
    }[];
  }
  export interface GetPatients {
    patients: {
      id: string;
      name: string;
      speciesId: string;
      breedId: string;
      sexId: string;
      sex?: {
        id: string;
        name: string;
      };
      breed?: {
        id: string;
        name: string;
        species?: {
          id: string;
          name: string;
        };
      };
      species?: {
        id: string;
        name: string;
      };
      integrationId: string;
      createdAt: string;
      updatedAt: string;
    }[];
  }
  export interface GetSpecies {
    species: {
      id: string;
      name: string;
      integrationId: string;
      createdAt: string;
      updatedAt: string;
      breeds?: {
        id: string;
        name: string;
      }[];
    }[];
  }
  export interface GetBreeds {
    breeds: {
      speciesId: string;
      id: string;
      name: string;
      integrationId: string;
      createdAt: string;
      updatedAt: string;
    }[];
  }
  export interface GetSexes {
    sexes: {
      id: string;
      name: string;
      species?: {
        id: string;
        name: string;
      };
      speciesId: string;
      integrationId: string;
      createdAt: string;
      updatedAt: string;
    }[];
  }
  export interface GetInitialData {
    partners: {
      id: string;
      name: string;
      practices?: {
        id: string;
        name: string;
        sites?: {
          id: string;
          name: string;
        }[];
      }[];
    }[];
  }
}
const apiClient = {
  connection: new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  }),
  authenticate: (token: string) => {
    return apiClient.connection.mutate({
      mutation: gql`
        mutation ($token: String!) {
          authenticate(token: $token) {
            firstName
            lastName
            email
            token
            isSuper
          }
        }
      `,
      variables: {
        token,
      },
    });
  },
  mutations: {
    CREATE_PARTNER: gql`
      mutation ($partner: PartnerInput!) {
        createPartner(partner: $partner) {
          id
          name
        }
      }
    `,
    UPDATE_PARTNER: gql`
      mutation ($partner: PartnerUpdateInput!) {
        updatePartner(partner: $partner) {
          id
          name
          singleClientOwnership
        }
      }
    `,
    CREATE_PRACTICE: gql`
      mutation ($practice: CreatePracticeInput!) {
        createPractice(practice: $practice) {
          id
          name
          colorCode
          contact
          currencyId
          email
          facebookUrl
          goLiveDate
          installation
          integration
          integrationId
          isActive
          isDeleted
          label
          languageId
          localeId
          migratedFrom
          serverId
          siteId
          websiteUrl
          timezoneId
          privateKey
          createdAt
          updatedAt
        }
      }
    `,
    UPDATE_PRACTICE: gql`
      mutation ($practice: UpdatePracticeInput!) {
        updatePractice(practice: $practice) {
          id
          name
          colorCode
          contact
          currencyId
          email
          facebookUrl
          goLiveDate
          installation
          integration
          integrationId
          isActive
          isDeleted
          label
          languageId
          localeId
          migratedFrom
          serverId
          siteId
          websiteUrl
          timezoneId
          privateKey
          createdAt
          updatedAt
        }
      }
    `,
    CREATE_PARTNER_HOOK: gql`
      mutation ($partnerHook: CreatePartnerHookInput!) {
        createPartnerHook(partnerHook: $partnerHook) {
          id
          name
          partnerId
          practiceId
          
          sendAppointment
          sendAppointmentStatus
          sendAppointmentType
          sendBlockoff
          sendBreed
          sendClient
          sendClientPhoneNumber
          sendCurrency
          sendEmployee
          sendInvoice
          sendLanguage
          sendLocale
          sendPatientReminder
          sendPatient
          sendOwnership
          sendPractice
          sendResource
          sendSex
          sendSpecies
          sendTimezone

          parser

          headers {
            name
            value
          }

          isActive
          url

          createdAt
          updatedAt
        }
      }
    `,
    UPDATE_PARTNER_HOOK: gql`
      mutation ($partnerHook: UpdatePartnerHookInput!) {
        updatePartnerHook(partnerHook: $partnerHook) {
          id
          name
          partnerId
          practiceId
          
          sendAppointment
          sendAppointmentStatus
          sendAppointmentType
          sendBlockoff
          sendBreed
          sendClient
          sendClientPhoneNumber
          sendCurrency
          sendEmployee
          sendInvoice
          sendLanguage
          sendLocale
          sendPatientReminder
          sendPatient
          sendOwnership
          sendPractice
          sendResource
          sendSex
          sendSpecies
          sendTimezone

          parser

          headers {
            name
            value
          }

          isActive
          url

          createdAt
          updatedAt
        }
      }
    `,
    // CREATE_CLIENT: gql`
    //   mutation ($siteId: String!, $client: ClientInput!) {
    //     createClient(siteId: $siteId, client: $client) {
    //       id
    //       firstName
    //       lastName
    //       emails {
    //         id
    //         type
    //         isPrimary
    //         value
    //         description
    //       }
    //       phoneNumbers {
    //         id
    //         type
    //         isPrimary
    //         value
    //         description
    //       }
    //     }
    //   }
    // `,
    // ARCHIVE_CLIENT: gql`
    //   mutation ($siteId: String!, $id: String!) {
    //     archiveClient(siteId: $siteId, id: $id) {
    //       id
    //       archivedAt
    //     }
    //   }
    // `,
    // CREATE_PATIENT: gql`
    //   mutation ($siteId: String!, $patient: PatientInput!) {
    //     createPatient(siteId: $siteId, patient: $patient) {
    //       id
    //       name
    //       sexId
    //       speciesId
    //       breedId
    //       color
    //     }
    //   }
    // `,
    RESET_PRACTICE: gql`
      mutation ($practice: ResetPracticeInput!) {
        resetPractice(practice: $practice) {
          success
        }
      }
    `,
    // CREATE_SPECIES: gql`
    //   mutation ($siteId: String!, $species: SpeciesInput!) {
    //     createSpecies(siteId: $siteId, species: $species) {
    //       id
    //       name
    //     }
    //   }
    // `,
    // CREATE_BREED: gql`
    //   mutation ($siteId: String!, $breed: BreedInput!) {
    //     createBreed(siteId: $siteId, breed: $breed) {
    //       id
    //       name
    //       speciesId
    //     }
    //   }
    // `,
    // CREATE_SEX: gql`
    //   mutation ($siteId: String!, $sex: SexInput!) {
    //     createSex(siteId: $siteId, sex: $sex) {
    //       id
    //       name
    //       speciesId
    //     }
    //   }
    // `,

    MARK_ENTITY_AS_FETCHED: gql`
      mutation ($markEntitiesAsFetched: MarkedAsFetchedInput!) {
        markEntitiesAsFetched(markEntitiesAsFetched: $markEntitiesAsFetched) {
          rowsAffected
        }
      }
    `,
  },
  queries: {
    GET_PARTNERS: gql`
      query {
        partners {
          id
          name
          isEnabled
          singleClientOwnership
        }
      }
    `,
    GET_PARTNER: gql`
      query ($id: UUID!) {
        partner(id: $id) {
          id
          isEnabled
          singleClientOwnership
          name
          createdAt
          updatedAt
        }
      }
    `,
    GET_PRACTICES: gql`
      query {
        practices {
          id
          name
          integration
          isActive
          createdAt
        }
      }
    `,
    GET_PRACTICE: gql`
      query ($id: UUID!) {
        practice(id: $id) {
          id
          name
          colorCode
          contact
          currencyId
          email
          facebookUrl
          goLiveDate
          installation
          integration
          integrationId
          isActive
          isDeleted
          label
          languageId
          localeId
          migratedFrom
          serverId
          siteId
          websiteUrl
          timezoneId
          privateKey
          createdAt
          updatedAt
        }
      }
    `,
    GET_PARTNER_HOOKS: gql`
      query {
        partnerHooks {
          id
          name
          partnerId
          practiceId
          parser
          sendAppointment
          sendAppointmentStatus
          sendAppointmentType
          sendBlockoff
          sendBreed
          sendClient
          sendClientPhoneNumber
          sendCurrency
          sendEmployee
          sendInvoice
          sendLanguage
          sendLocale
          sendPatientReminder
          sendPatient
          sendOwnership
          sendPractice
          sendResource
          sendSex
          sendSpecies
          sendTimezone

          headers {
            name
            value
          }

          isActive
          url

          createdAt
          updatedAt
        }
      }
    `,
    GET_PARTNER_HOOK: gql`
      query ($id: UUID!) {
        partnerHook(id: $id) {
          id
          name
          partnerId
          practiceId
          parser

          sendAppointment
          sendAppointmentStatus
          sendAppointmentType
          sendBlockoff
          sendBreed
          sendClient
          sendClientPhoneNumber
          sendCurrency
          sendEmployee
          sendInvoice
          sendLanguage
          sendLocale
          sendPatientReminder
          sendPatient
          sendOwnership
          sendPractice
          sendResource
          sendSex
          sendSpecies
          sendTimezone


          headers {
            name
            value
          }

          isActive
          url

          createdAt
          updatedAt
        }
      }
    `,
    GET_SITES: gql`
      query {
        sites {
          id
          name
          practiceId
        }
      }
    `,
    // GET_CLIENTS: gql`
    //   query ($siteId: String!, $isArchived: Boolean) {
    //     clients(siteId: $siteId, isArchived: $isArchived) {
    //       id
    //       firstName
    //       lastName
    //       emails {
    //         id
    //         isPrimary
    //         type
    //         value
    //       }
    //       phoneNumbers {
    //         id
    //         isPrimary
    //         type
    //         value
    //       }
    //     }
    //   }
    // `,
    // GET_CLIENT: gql`
    //   query ($siteId: String!, $id: String!) {
    //     client(siteId: $siteId, id: $id) {
    //       id
    //       firstName
    //       lastName
    //       emails {
    //         id
    //         isPrimary
    //         type
    //         value
    //       }
    //       phoneNumbers {
    //         id
    //         isPrimary
    //         type
    //         value
    //       }
    //     }
    //   }
    // `,
    // GET_PATIENTS: gql`
    //   query ($siteId: String!, $clientId: String) {
    //     patients(siteId: $siteId, clientId: $clientId) {
    //       id
    //       name
    //       sex {
    //         id
    //         name
    //       }
    //       breed {
    //         species {
    //           id
    //           name
    //         }
    //         id
    //         name
    //       }
    //     }
    //   }
    // `,
    // GET_SEXES: gql`
    //   query ($siteId: String!) {
    //     sexes(siteId: $siteId) {
    //       id
    //       name
    //       species {
    //         id
    //         name
    //       }
    //     }
    //   }
    // `,
    // GET_SPECIES: gql`
    //   query ($siteId: String!) {
    //     species(siteId: $siteId) {
    //       id
    //       name
    //       breeds {
    //         id
    //         name
    //       }
    //     }
    //   }
    // `,
    // GET_BREEDS: gql`
    //   query ($siteId: String!, $speciesId: String!) {
    //     breeds(siteId: $siteId, speciesId: $speciesId) {
    //       id
    //       name
    //     }
    //   }
    // `,
    // GET_APPOINTMENTS: gql`
    //   query ($siteId: String!, $startingAt: String!, $endingAt: String!) {
    //     appointments(
    //       siteId: $siteId
    //       startingAt: $startingAt
    //       endingAt: $endingAt
    //     ) {
    //       id
    //       startsAt
    //       endsAt
    //       reason
    //       resourceId
    //       client {
    //         id
    //         firstName
    //         lastName
    //       }
    //       patient {
    //         id
    //         name
    //       }
    //       resource {
    //         id
    //         fullName
    //       }
    //       appointmentType {
    //         id
    //         name
    //       }
    //       appointmentStatus {
    //         id
    //         name
    //       }
    //     }
    //   }
    // `,
    // GET_CALENDAR: gql`
    //   query (
    //     $siteId: String!
    //     $startingAt: String!
    //     $endingAt: String!
    //     $resourceId: String
    //   ) {
    //     appointments(
    //       siteId: $siteId
    //       startingAt: $startingAt
    //       endingAt: $endingAt
    //       resourceId: $resourceId
    //     ) {
    //       id
    //       startsAt
    //       endsAt
    //       reason
    //       resourceId
    //       client {
    //         id
    //         firstName
    //         lastName
    //       }
    //       patient {
    //         id
    //         name
    //       }
    //       appointmentStatus {
    //         id
    //         name
    //       }
    //       appointmentType {
    //         id
    //         name
    //       }
    //     }
    //     blockoffs(
    //       siteId: $siteId
    //       startingAt: $startingAt
    //       endingAt: $endingAt
    //       resourceId: $resourceId
    //     ) {
    //       id
    //       startsAt
    //       endsAt
    //       resourceId
    //       resource {
    //         id
    //         fullName
    //       }
    //     }
    //   }
    // `,
    GET_CURRENT_USER_DATA: gql`
      query {
        partners {
          id
          name
          # practices{
          #     id
          #     name
          #     sites{
          #         id
          #         name
          #     }
          # }
        }
      }
    `,
    // GET_RESOURCES: gql`
    //   query ($siteId: String!) {
    //     resources(siteId: $siteId) {
    //       id
    //       fullName
    //     }
    //   }
    // `,
    GET_ENTITIES: gql`
      query {
        getEntities {
          name
          key
        }
      }
    `,
  },
};

export default apiClient;
