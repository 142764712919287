import { useState } from "react";

let publicKey = "c2d5f9c0adad1b6a6ad0449571101c316e713fe891fd3caba1";

if (window.location.host.includes("vetsuite.io")) {
  publicKey = "85bc65b7a5ca7dbe04ba81256721a63de606465d53afec08df";
}

const Authenticate = () => {
  const [state, setState] = useState({ locationId: "datahub-east-a1" });
  return (
    <div>
      <br />
      <br />
      <br />
      <a
        href={`https://authentication.vetsuite.io?publicKey=${publicKey}&state=${state.locationId}`}
      >
        Authenticate
      </a>
    </div>
  );
};
export default Authenticate;
