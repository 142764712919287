import styled from "styled-components";

export const SelectFieldStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  margin: 5px 0px;
  .MuiFormControl-root {
    width: 100%;
  }

  .label {
    color: #516382;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .MuiInputBase-root {
    width: 100%;
    background: #ffffff;
    font-size: 13px;
    line-height: 18px;
    color: #2b3952;

    .MuiSelect-multiple {
      padding: 4px 7px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      box-shadow: 0 0 0 30px #e8f0fe inset !important ;
      padding: 6.5px 11px;
    }
    input {
      z-index: 1;
    }
    &.Mui-focused {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: #00b0cc !important;
        }
      }
    }
    &.Mui-disabled {
      cursor: default;
      background: rgba(235, 238, 245, 0.2);
    }
  }

  .Mui-disabled {
    -webkit-text-fill-color: #b9bec7 !important;
  }
  .chips-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5;
  }

  .error {
    color: red;
    font-size: 11px;
    margin: 0px;
    margin-top: 5px;
  }
`;
