import { useMutation } from "@apollo/client";
import { Box, Button, FormControl, Input, InputLabel, Typography } from "@mui/material";
import { useForm } from "react-hook-form"
import apiClient, { ApiClient } from "../../lib/apiClient";

interface PartnerFormInputs {
    name: string
}

interface PartnerCreateProps {
    onComplete?: Function
}
const CreatePartner = (props: PartnerCreateProps)=>{
    const {
        register,
        handleSubmit,
        // formState: { errors },
    } = useForm<PartnerFormInputs>()
    const [createPartner, { loading, error }] = useMutation<ApiClient.GetPartners["partners"][0]>(apiClient.mutations.CREATE_PARTNER);

    const handleCreatePartner = (partner: any)=>{
        createPartner({
            variables: {partner}
        }).then(()=>{
            if(props.onComplete) props.onComplete()
        }).catch((error)=>{
            console.log(error)
        })
    }

    return (
        <Box
            sx={{p: 2}}
        >
            <form onSubmit={handleSubmit(handleCreatePartner)}>
                {/* register your input into the hook by invoking the "register" function */}
                {loading ? (<>
                    Loading...
                </>):(
                    <>
                        {error && error?.message}
                        <FormControl>
                            <Typography component="h4">
                                New Partner
                            </Typography>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl>
                            <InputLabel htmlFor="name">Partner Name</InputLabel>
                            <Input id="name" defaultValue="" {...register("name")} required={true} />
                        </FormControl>

                        {/* include validation with required or other standard HTML validation rules */}
                        {/* errors will return when field validation fails  */}
                        <br />
                        <Button type="submit" variant="contained">Create</Button>
                    </>
                )}
            </form>
        </Box>
    )
}

export default CreatePartner