import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, FormControl, FormControlLabel, Input, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form"
import apiClient, { ApiClient } from "../../lib/apiClient";
import { useEffect, useState } from "react";
import { getValue } from "@testing-library/user-event/dist/utils";

interface PartnerHookFormInputs {
    name: string
    practiceId: string
    url: string

    sendAppointment: boolean,
    sendAppointmentStatus: boolean,
    sendAppointmentType: boolean,

    sendBlockoff: boolean,
    sendBreed: boolean,
    sendClient: boolean,
    sendClientPhoneNumber: boolean,
    sendCurrency: boolean,
    sendEmployee: boolean,
    sendInvoice: boolean,
    sendLanguage: boolean,
    sendLocale: boolean,
    sendPatientReminder: boolean,
    sendPatient: boolean,
    sendOwnership: boolean,
    sendPractice: boolean,
    sendResource: boolean,
    sendSex: boolean,
    sendSpecies: boolean,
    sendTimezone: boolean,

    isActive: boolean
    headers: {
        name: string
        value: string
    }[]
}

interface PartnerHookUpdateProps {
    partnerHookId: string,
    onComplete?: Function
}
const UpdatePartnerHook = (props: PartnerHookUpdateProps)=>{
    const {
        register,
        handleSubmit,
        // formState: { errors },
        reset,
        setValue
    } = useForm<PartnerHookFormInputs>()

    const [state, setState] = useState<{headers: PartnerHookFormInputs["headers"]}>({
        headers: [],
    })
        
    const [updatePartnerHook, { loading, error }] = useMutation<{updatePartnerHook: ApiClient.GetPartnerHook["partnerHook"]}>(apiClient.mutations.UPDATE_PARTNER_HOOK);
    const partnerHookQuery = useQuery<ApiClient.GetPartnerHook>(apiClient.queries.GET_PARTNER_HOOK, {
        variables: {
            id: props.partnerHookId
        }
    });
    
    const partnerHook = partnerHookQuery.data?.partnerHook

    useEffect(()=>{
        setState({
            headers: partnerHookQuery.data?.partnerHook.headers ?? []
        })

        console.log(partnerHook)
        
        reset({
            name: partnerHook?.name ?? '',
            practiceId: partnerHook?.practiceId ?? '',
            url: partnerHook?.url ?? '',
            sendAppointment: partnerHook?.sendAppointment ?? false,
            sendAppointmentStatus: partnerHook?.sendAppointmentStatus ?? false,
            sendAppointmentType: partnerHook?.sendAppointmentType ?? false,

            sendBlockoff: partnerHook?.sendBlockoff ?? false,
            sendBreed: partnerHook?.sendBreed ?? false,
            sendClient: partnerHook?.sendClient ?? false,
            sendClientPhoneNumber: partnerHook?.sendClientPhoneNumber ?? false,
            sendCurrency: partnerHook?.sendCurrency ?? false,
            sendEmployee: partnerHook?.sendEmployee ?? false,
            sendInvoice: partnerHook?.sendInvoice ?? false,
            sendLanguage: partnerHook?.sendLanguage ?? false,
            sendLocale: partnerHook?.sendLocale ?? false,
            sendPatientReminder: partnerHook?.sendPatientReminder ?? false,
            sendPatient: partnerHook?.sendPatient ?? false,
            sendOwnership: partnerHook?.sendOwnership ?? false,
            sendPractice: partnerHook?.sendPractice ?? false,
            sendResource: partnerHook?.sendResource ?? false,
            sendSex: partnerHook?.sendSex ?? false,
            sendSpecies: partnerHook?.sendSpecies ?? false,
            sendTimezone: partnerHook?.sendTimezone ?? false,

            isActive: partnerHook?.isActive ?? false,
            headers: partnerHook?.headers?.map((header)=>({
                name: header.name ?? '',
                value: header.value ?? ''
            }))
        })
    }, [partnerHookQuery.data?.partnerHook])
    

    const practicesQuery = useQuery<ApiClient.GetPractices>(apiClient.queries.GET_PRACTICES);



    const handleUpdatePartnerHook = (partnerHookParams: any)=>{
        if(partnerHook?.id){
            const variables = {
                id: partnerHook.id,
                partnerHook: {
                    name: partnerHookParams.name,
                    practiceId: partnerHookParams.practiceId,
                    url: partnerHookParams.url,
                    headers: partnerHookParams.headers,
                    parser: '{}',
                    isActive: partnerHookParams.isActive,
                    sendAppointment: partnerHookParams.sendAppointment,
                    sendAppointmentStatus: partnerHookParams.sendAppointmentStatus,
                    sendAppointmentType: partnerHookParams.sendAppointmentType,
                    sendBlockoff: partnerHookParams.sendBlockoff,
                    sendBreed: partnerHookParams.sendBreed,
                    sendClient: partnerHookParams.sendClient,
                    sendClientPhoneNumber: partnerHookParams.sendClientPhoneNumber,
                    sendCurrency: partnerHookParams.sendCurrency,
                    sendEmployee: partnerHookParams.sendEmployee,
                    sendInvoice: partnerHookParams.sendInvoice,
                    sendLanguage: partnerHookParams.sendLanguage,
                    sendLocale: partnerHookParams.sendLocale,
                    sendPatientReminder: partnerHookParams.sendPatientReminder,
                    sendPatient: partnerHookParams.sendPatient,
                    sendOwnership: partnerHookParams.sendOwnership,
                    sendPractice: partnerHookParams.sendPractice,
                    sendResource: partnerHookParams.sendResource,
                    sendSex: partnerHookParams.sendSex,
                    sendSpecies: partnerHookParams.sendSpecies,
                    sendTimezone: partnerHookParams.sendTimezone,
                    id: partnerHook.id
                }
            }

            console.log(variables)
            updatePartnerHook({
                variables
            }).then(()=>{
                if(props.onComplete) props.onComplete()
            }).catch((error)=>{
                console.log(error)
            })
        }
    }
    const handleAddHeader = ()=>{
        setState({
            headers: [
                ...(state?.headers ?? []),
                {
                    name: "",
                    value: ""
                }
            ]
        })
    }
    
    const handleSwitchChange = (key: keyof PartnerHookFormInputs, e: React.ChangeEvent<HTMLInputElement>)=>{
        setValue(key, !!e.target.checked)
    }

    const controllerStyle = {width: '100%'}
    const defaultPracticeId = partnerHookQuery.data?.partnerHook.practiceId ?? ""
    return (
        <Box
            sx={{p: 2}}
            style={{minWidth: 400}}
        >
            {!partnerHookQuery.loading && !practicesQuery.loading && (

                <form onSubmit={handleSubmit(handleUpdatePartnerHook)}>
                    {/* register your input into the hook by invoking the "register" function */}
                    {loading ? (<>
                        Loading...
                    </>):(
                        <>
                            {error && error?.message}
                            <FormControl>
                                <Typography component="h4">
                                    New PartnerHook
                                </Typography>
                            </FormControl>
                            <br />
                            <br />
                            <FormControl style={controllerStyle}>
                                <TextField id="name" defaultValue="" {...register("name")} required={true} variant='filled' label="Partner Hook Name" />
                            </FormControl>
                            <br />
                            <br />
                            
                            <FormControl style={controllerStyle}> 
                                {practicesQuery.data?.practices?.length != 0 && (
                                    <Select id="practiceId" {...register("practiceId")} variant="filled" label='Practice' defaultValue={defaultPracticeId}>
                                        {practicesQuery.data?.practices?.map((practice)=>(
                                            <MenuItem key={practice.id} value={practice.id}>{practice.name}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </FormControl>
                            
                            <br />
                            <br />
                            Practice Level Hooks
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={handleSwitchChange.bind(null, 'sendInvoice')} 
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendInvoice
                                        }
                                    />
                                } 
                                label='Send Invoice'  
                            />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={
                                            handleSwitchChange.bind(null, 'sendSpecies')
                                        }
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendSpecies
                                        } />  
                                }
                                label='Send Species'  
                            />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={handleSwitchChange.bind(null, 'sendBreed')} 
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendBreed
                                        }
                                    />
                                } 
                                label='Send Breeds'  
                            />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={handleSwitchChange.bind(null, 'sendSex')} 
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendSex
                                        } 
                                    />
                                } 
                                label='Send Sex'  
                            />

                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={
                                            handleSwitchChange.bind(null, 'sendTimezone')
                                        } 
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendTimezone
                                        } 
                                    />
                                } 
                                label='Send Timezones'  
                            />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={
                                            handleSwitchChange.bind(null, 'sendBlockoff')
                                        } 
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendBlockoff
                                        } 
                                    />
                                } 
                                label='Send Blockoffs'
                            />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={handleSwitchChange.bind(null, 'sendResource')} 
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendResource
                                        } 
                                    />
                                } 
                                label='Send Resources'
                            />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={
                                            handleSwitchChange.bind(null, 'sendEmployee')
                                        } 
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendEmployee
                                        } 
                                    />
                                }
                                label='Send Employees'
                            />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch onChange={
                                        handleSwitchChange.bind(null, 'sendCurrency')
                                    } 
                                    defaultChecked={
                                        partnerHookQuery.data?.partnerHook?.sendCurrency
                                    } />
                                } 
                                label='Send Currencies'
                            />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={
                                            handleSwitchChange.bind(null, 'sendLanguage')
                                        } 
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendLanguage
                                        } 
                                    />
                                } 
                                label='Send Languages'
                            />
                            <br />
                            <FormControlLabel
                                control={
                                    <Switch 
                                        onChange={
                                            handleSwitchChange.bind(null, 'sendLocale')
                                        }
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendLocale
                                        }
                                    />
                                } 
                                label='Send Locales'
                            />
                            <br />
                            <FormControlLabel
                                control={
                                    <Switch onChange={
                                        handleSwitchChange.bind(null, 'sendAppointmentType')
                                    } 
                                    defaultChecked={
                                        partnerHookQuery.data?.partnerHook?.sendAppointmentType
                                    } 
                                />} 
                                label='Send Appointment Types'  
                            />
                            <br />
                            <FormControlLabel
                                control={
                                    <Switch onChange={
                                        handleSwitchChange.bind(null, 'sendAppointmentStatus')
                                    } 
                                    defaultChecked={
                                        partnerHookQuery.data?.partnerHook?.sendAppointment
                                    } 
                                />} 
                                label='Send Appointment Statuses'  
                            />
                            <br />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={
                                            handleSwitchChange.bind(null, 'sendClient')
                                        } 
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendClient
                                        } 
                                    />
                                } 
                                label='Send Clients'
                            />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={
                                            handleSwitchChange.bind(null, 'sendClientPhoneNumber')
                                        } 
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendClientPhoneNumber
                                        } 
                                    />
                                } 
                                label='Send Client Phone Numbers'
                            />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={
                                            handleSwitchChange.bind(null, 'sendPatient')
                                        }
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendPatient
                                        } 
                                    />
                                } 
                                label='Send Patients'
                            />
                            <br />
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        onChange={
                                            handleSwitchChange.bind(null, 'sendOwnership')
                                        }
                                        defaultChecked={
                                            partnerHookQuery.data?.partnerHook?.sendOwnership
                                        } 
                                    />
                                } 
                                label='Send Ownerships'
                            />
                            
                            <br />
                            <FormControlLabel
                                control={
                                    <Switch onChange={
                                        handleSwitchChange.bind(null, 'sendAppointment')
                                    } 
                                    defaultChecked={
                                        partnerHookQuery.data?.partnerHook?.sendAppointment
                                    } 
                                />} 
                                label='Send Appointments'  
                            />
                            <br />
                            <FormControlLabel
                                control={
                                    <Switch onChange={
                                        handleSwitchChange.bind(null, 'sendBlockoff')
                                    } 
                                    defaultChecked={
                                        partnerHookQuery.data?.partnerHook?.sendBlockoff
                                    } 
                                />} 
                                label='Send Blockoffs'  
                            />
                            <br />
                            <br />
                            <FormControlLabel
                                control={
                                    <Switch onChange={
                                        handleSwitchChange.bind(null, 'sendPatientReminder')
                                    } 
                                    defaultChecked={
                                        partnerHookQuery.data?.partnerHook?.sendPatientReminder
                                    } 
                                />} 
                                label='Send Patient Reminders'  
                            />
                            <br />
                            <FormControlLabel
                                control={
                                    <Switch onChange={
                                        handleSwitchChange.bind(null, 'sendInvoice')
                                    } 
                                    defaultChecked={
                                        partnerHookQuery.data?.partnerHook?.sendInvoice
                                    } 
                                />} 
                                label='Send Invoices'  
                            />
                            <br />
                            <br />
                           
                            Headers:
                            {state?.headers?.map((header, i)=>(
                                <div style={{margin: '0px -16px'}} key={i}>
                                    <FormControl sx={{p: 2}}> 
                                        <TextField id={`headers.${i}.name`} defaultValue="" {...register(`headers.${i}.name`)} required={true} label='Name' variant='filled' />
                                    </FormControl>
                                    <FormControl sx={{p: 2}}> 
                                        <TextField id={`headers.${i}.value`} defaultValue="" {...register(`headers.${i}.value`)} required={true}  label='Value' variant='filled' />
                                    </FormControl>
                                </div>
                            ))}
                            <br />
                            <Button onClick={handleAddHeader} variant="contained">Add Header</Button>

                            <br />
                            <br />
                            <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'isActive')} defaultChecked={partnerHookQuery.data?.partnerHook?.isActive} />} label='Active'  /> 

                            <br />
                            <FormControl style={controllerStyle} > 
                                <TextField id={`url`} defaultValue="" {...register(`url`)} required={true} label='Url' variant='filled' />
                            </FormControl>
                            <br />
                
                            {/* include validation with required or other standard HTML validation rules */}
                            {/* errors will return when field validation fails  */}
                            <br />
                            <Button type="submit" variant="contained">Update</Button>
                        </>
                    )}
                </form>
            )}
        </Box>
    )
}

export default UpdatePartnerHook